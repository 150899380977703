@keyframes Floatingy {
  from {
    -webkit-transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(50px, 0);
  }
  to {
    -webkit-transform: translate(0, 0px);
  }
}

@keyframes Floatingx {
  from {
    -webkit-transform: translate(50px, 0px);
  }
  65% {
    -webkit-transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(50px, 0px);
  }
}
.grid.grid--loaded .grid__item {
  position: absolute;
}
.grid__item .grid__link .grid__img{
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
}
.grid__item .grid__link .grid__img:hover{
  transform: translate3d(-22px, -28px, 16px);
}

.bg-overlay {
  position: relative;
  z-index: 0;
}

.bg-overlay::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.85;
  z-index: -1;
  /* purple gradient */
  background: linear-gradient(-47deg, #9236fc 0%, #3315c9 100%);
}

html {
  scroll-behavior: smooth;
}
